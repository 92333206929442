<template>
  <b-row class="no-gutters mx-auto justify-content-center p_y__3">
    <b-col class="col-12 col-md-8 col-lg-6 col-xl-4 p-1">
      <b-card no-body class="pt-2 px-3 pb-4">
        <h6 class="mb-0 font-weight-bold text-uppercase" style="color:var(--emotiva-pink)">
          {{ $t("welcome") }}
        </h6>
        <hr class="my-2 mx-n3" />
        <b-row class="no-gutters mt-4">
          <b-col class="col-12 col-sm-auto">
            <img
              style="width: 100px"
              src="../assets/images/foresight-logo.png"
              alt="Image"
              class="rounded-0"
            />
          </b-col>
          <b-col class="col-12 col-sm">
            <b-card-body class="pt-sm-0 pl-sm-3 px-0 pb-1">
              <validation-observer v-slot="{ valid }">
                <b-form-group
                  label-class="font-weight-bold pb-0"
                  :label="$t('password')"
                >
                  <validation-provider
                    name="password"
                    :rules="{ required: true, password }"
                    v-slot="validationContext"
                  >
                    <p class="small text-muted">
                      {{ $t("passwordRulesText") }}
                    </p>
                    <b-form-input
                      v-model="password"
                      type="password"
                      :placeholder="$t('password')"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-row class="no-gutters justify-content-end mt-5">
                  <b-col class="col-12 col-sm-auto">
                    <b-button
                      class="confirm-btn"
                      :disabled="!valid"
                      block
                      @click="confirmUser"
                    >
                      {{ $t("button.confirm") }}
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: "",
      passwordConfirmation: ""
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    confirmUser() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/confirmUser", {
          password: this.password,
          token: this.token
        })
        .then(() => {
          this.$store.commit("success/SET_SUCCESS_INFO", {
            titleText: "dialog.success.confirmClientAccount.title",
            messageText: "dialog.success.confirmClientAccount.message",
            confirmBtnCallback: this.goToLoginPage,
            confirmBtnLabel: "button.goToLogin"
          });
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    goToLoginPage() {
      this.$router.replace({ name: "Login" });
    }
  }
};
</script>

<style scoped>
.confirm-btn{
  background-color: var(--emotiva-pink);
  color:#fff;
  padding: 15px;
  border-radius: 28px;
  min-width: 109px;
  min-height: 56px;
}</style>
